import request from "@/routes/api/api_conextion";
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { getFromLocal, saveInLocal } from "@/commons/utils/storage";

function getResourece(resource, params = null) {
  return new Promise((resolve) => {
    setTimeout(() => {
      request({
        url: resource,
        method: "get",
        params: params,
      }).then((request) => {
        // saveInLocal(resource, request)
        resolve(request);
      });
    }, 1000);
  });
}

export async function getEstadoCivil() {
  return getResourece("/marital-status");
}
export async function getSucursales(id) {
  return getResourece("/sucursales/" + id);
}
export async function getTipoViviendas() {
  return getResourece("/properties");
}

export async function getEstudios() {
  return getResourece("/studies");
}

export async function getFormasDeEnterarse() {
  return getResourece("/find-out");
}

export async function getRelacionesLaborales() {
  return getResourece("/employment-relations");
}

export async function getCoinTypes() {
  return getResourece("/coinTypes");
}

export async function getSelds() {
  return getResourece("/workers");
}

export async function getVentasTop() {
  return getResourece("/ventasTop");
}

export async function getDepartamentos() {
  return getResourece("/departamentos");
}

export async function getBancos() {
  return getResourece("/bancos");
}

export async function getRubros() {
  return getResourece("/rubros");
}

export async function getMunicipios(params) {
  return getResourece("/getMunicipios/" + params);
}

export async function getLocalidad(params) {
  return getResourece("/getLocalidad/" + params);
}
export async function getMunicipiosByName(params) {
  return getResourece("/municipio/" + params);
}

export async function getLocalidadByName(params) {
  return getResourece("/localidad/" + params);
}
export async function getDepartamentoByName(params) {
  return getResourece("/departamento/" + params);
}

export async function getDocumentsType(params, active = true) {
  return getResourece("/documents/" + params + "/" + active);
}

export async function getPaises(params) {
  return getResourece("/getPaises", params);
}

export async function getPais(id) {
  return getResourece("/getPaises/" + id);
}

export async function getDirectionConfig() {
  return getResourece("/direction/config");
}
export async function getFormasPago() {
  return getResourece("/getFormasPago");
}
export async function getDocIde(id) {
  return getResourece("/getdocide/" + id);
}
export async function getInstantorSettings() {
  return getResourece("/instantor/config");
}
export async function getRequisitos(id) {
  return getResourece("/requisitosgarante/" + id);
}

export async function validateContact(email, phone, document) {
  return getResourece(
    "/validarcontacto?" +
      "email=" +
      email +
      "&phone=" +
      phone +
      "&document=" +
      document
  );
}

export async function getIp() {
  return axios
    .create({
      baseURL: process.env.VUE_APP_IPL_URL, // url = base url + request url
      // withCredentials: true, // send cookies when cross-domain requests
      timeout: 5000, // request timeout
    })
    .get("/json");
}

export async function getMotivoRechazo(loan) {
  var ruta = loan ? "/getmotivorechazo/" + loan : "/getmotivorechazo";
  return getResourece(ruta);
}

export async function validateNames(type, names, document) {
  return getResourece(
    "/validarnames?" +
      "type=" +
      type +
      "&names=" +
      names +
      "&document=" +
      document
  );
}

export async function getTextProduct(params) {
    return getResourece("/getTextProduct/" + params);
}

export async function getEmpresasOferentes() {
    return getResourece("/getEmpresasOferentes" );
}

export async function getMensajeFinal(params) {
    return getResourece("/getMensajeFinal/" + params);
}