import {isEmpty, merge} from "@/commons/utils/functions";


const getDefaultState = () => {
    return {
        product: {},
        start_on_list: false,
        applicant: {
            numero_documento: {},
            info_extra: {}
        },
        company: {},
        identities: {},
        form_product: {
            garante: false,
            start_on_select: false,
            product_type_person: true,
            loan: null,
            monto: null,
            monto_cuota: null,
            plazo: null,
            tasa: null,
            primer_vencimiento: null,
            emision: null,
            ventas: null,
            cedula: null,
            jubilado: false,
            listablanca:null,
            current_step:null,
            verify_route:false,
            valor_vehiculo: null,
            valor_seguro: null,
            entrega_inicial: null,
            valor_transferencia:null,

        },
        sucursal:false,
    }
};

const state = getDefaultState();

const mutations = {
    SET_PRODUCT: (state, product) => {
        state.product = product;
        if (product.tipo_de_prestamo == 2) {
            state.form_product.product_type_person = false;
        }
    },
    SET_FORM_PRODUCT: (state, data) => {
        state.form_product = merge(state.form_product, data);
    },
    SET_START_ON_LIST: (state) => {
        state.start_on_list = true;
    },
    SET_APPLICANT: (state, data) => {
        state.applicant = merge(state.applicant, data);
    },
    SET_COMPANY: (state, data) => {
        state.company = merge(state.company, data);
    },
    SET_APPLICANT_IDENTITIES: (state, data) => {
        if (Object.prototype.hasOwnProperty.call(data, "identity")) {
            state.identities[data.identity.document] = data.identity.number;
        }
    },
    RESSET_FORM_PRODUCT: (state) => {
        state.form_product = getDefaultState().form_product;
    },
    RESSET_APPLICANT: (state) => {
        state.applicant = getDefaultState().applicant;
    },
    RESSET_COMPANY: (state) => {
        state.applicant = getDefaultState().company;
    },
    START_ON_SELECT: () => {
        state.start_on_select = true;
    },
    SET_SUCURSAL: (state, data) => {
        state.sucursal = data;
    },

};

const actions = {
    setProducto({commit}, product) {
        commit('SET_PRODUCT', product);
        this._vm.$session.set('product', state.product);
    },
    async setFormProduct({commit}, client) {
        commit('SET_FORM_PRODUCT', client);
        this._vm.$session.set('form_product', state.form_product);
    },
    setStartOnList({commit},) {
        commit('SET_START_ON_LIST');
    },
    setApplicant({commit}, client = {}) {
        commit('SET_APPLICANT', client);
        this._vm.$session.set('applicant', state.applicant);
    },
    setCompany({commit}, client = {}) {
        commit('SET_COMPANY', client);
        this._vm.$session.set('company', state.company);
    },
    setApplicantIdentities({commit}, data = {}) {
        commit('SET_APPLICANT_IDENTITIES', data);
    },
    resetFormProduct({commit}) {
        commit('RESSET_FORM_PRODUCT');
        this._vm.$session.set('form_product', state.form_product);
    },
    resetApplicant({commit}) {
        commit('RESSET_APPLICANT');
        this._vm.$session.set('applicant', state.applicant);
    },
    resetCompany({commit}) {
        commit('RESSET_COMPANY');
        this._vm.$session.set('company', state.company);
    },
    startOnSelect({commit}) {
        commit('START_ON_SELECT');
    },
    validatePrecondition({state}, precondition) {
        console.log('validatePrecondition')
        return !isEmpty(state.form_product[precondition]);
    },
    setSucursal({commit},data) {
        commit('SET_SUCURSAL',data);
        this._vm.$session.set('sucursal', data);
    },


}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}
