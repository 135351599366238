import request from "@/routes/api/api_conextion";


export function resendCodeDocSign(params) {
    return request({
        url: `/vale/code/reset`,
        method: 'post',
        data: params
    })
}
export function getDocToSign(params) {
    return request({
        url: `/vale/code/route`,
        method: 'post',
        data: params
    })
}
export function setDocSign(alias,envelop,event) {
    return request({
        url: '/vale/sing/'+alias+'/'+envelop,
        method: 'post',
        data: event
    })
}

export function getDocsSign(params) {
    return request({
        url: `/vale/docs/sing`,
        method: 'post',
        data: params
    })
}
export function setNaat(alias,solicitud) {
    return request({
        url: '/vale/respuesta/'+alias+'/'+solicitud+'/naat',
        method: 'post'
    })
}
export function createValidationNaat(solicitud,params) {
    return request({
        url: '/vale/naat/validation/create/'+solicitud,
        method: 'post',
        data: params
    })
}

export function saveValidationNaat(solicitud) {
    return request({
        url: '/vale/naat/validation/save/'+solicitud,
        method: 'post'
    })
}

export function getCredencialesNaat() {
    return request({
        url: '/vale/naat/credenciales',
        method: 'get'
    })
}

export function saveFaceNaat(solicitud,params) {
    return request({
        url: '/vale/naat/validation/saveface/'+solicitud,
        method: 'post',
        data: params
    })
}
